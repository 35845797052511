import * as s from '../styles/globalStyles';
import React from 'react';

function DescriptionContainer(CONFIG) {
  return (
    <s.Container jc={'center'} ai={'center'} style={{ width: '50%' }}>
      <s.TextDescription
        style={{
          textAlign: 'center',
          color: 'var(--secondary-text)',
        }}
      >
        Please make sure you are connected to the right network (
        {CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please note:
        Once you make the purchase, you cannot undo this action.
      </s.TextDescription>
      <s.SpacerSmall />
    </s.Container>
  );
}

export default DescriptionContainer;
