import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {fetchData} from './redux/data/dataActions';
import * as s from './styles/globalStyles';
import MintingContainer from './components/MintingContainer';
import {ResponsiveWrapper, StyledLogo} from './styles/containerStyles';
import DescriptionContainer from './components/DescriptionContainer';
import ContainerPaused from './components/PausedContainer';

function App() {
  let store = useStore();
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: '',
    SCAN_LINK: '',
    NETWORK: {
      NAME: '',
      SYMBOL: '',
      ID: 0,
    },
    NFT_NAME: '',
    SYMBOL: '',
    MAX_SUPPLY: 0,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MAX_MINT_AMOUNT: 1,
    MARKETPLACE: '',
    MARKETPLACE_LINK: '',
    SHOW_BACKGROUND: true,
    SHOW_GIF: false,
    SHOW_LOGO: false,
    PAUSED: true
  });

  const claimNFTs = () => {
    console.log('claimNFTs');
    let cost = data.cost;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);

    if (mintAmount > data.maxMintAmount) {
      setFeedback(
        'sorry, you reached max mint amount of ' + data.maxMintAmount,
      );
      return;
    }
    setFeedback(`Minting your ${CONFIG.NFT_NAME} NFT`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once('error', (err) => {
        console.log(err);
        setFeedback('Sorry, something went wrong please try again later.');
        setClaimingNft(false);
      })
      .then((receipt) => {
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`,
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > data.maxMintAmount) {
      newMintAmount = data.maxMintAmount;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== '' && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch('/config/config.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  console.log('data', data);
  let container;
  if (data.paused) {
    container = ContainerPaused('paused');
  } else if (data.onlyWhitelisted && !data.isWhitelisted) {
    container = ContainerPaused('sorry, but you are not on the whitelist');
  } else {
    container = MintingContainer(
      data,
      CONFIG,
      blockchain,
      dispatch,
      getData,
      feedback,
      claimingNft,
      decrementMintAmount,
      mintAmount,
      incrementMintAmount,
      claimNFTs,
    );
  }

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={'center'}
        style={{ padding: 24, backgroundColor: 'var(--primary)' }}
        image={CONFIG.SHOW_BACKGROUND ? '/config/images/dosdles_bg.png' : null}
      >
        <StyledLogo src={CONFIG.SHOW_LOGO ? '/config/images/logo.png' : null} />
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.SpacerLarge />
          {container}
          <s.SpacerLarge />
        </ResponsiveWrapper>
        <s.SpacerMedium />
        {DescriptionContainer(CONFIG)}
      </s.Container>
    </s.Screen>
  );
}

export default App;
