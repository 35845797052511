// log

import store from '../store';

const fetchDataRequest = () => {
  return {
    type: 'CHECK_DATA_REQUEST',
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: 'CHECK_DATA_SUCCESS',
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: 'CHECK_DATA_FAILED',
    payload: payload,
  };
};

export const fetchData = (account) => {
  console.log('dataActions::fetchData...', account);
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let totalSupply;
      let cost;
      let maxSupply;
      let paused;
      let onlyWhitelisted;
      let isWhitelisted;
      let maxMintAmount;

      let methods = await store.getState().blockchain.smartContract.methods;

      console.log('fetchData::methods', methods);

      onlyWhitelisted = await store
        .getState()
        .blockchain.smartContract.methods.onlyWhitelisted()
        .call();

      isWhitelisted = await store
        .getState()
        .blockchain.smartContract.methods.isWhitelisted(account)
        .call();

      totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();

      cost = await store
        .getState()
        .blockchain.smartContract.methods.cost()
        .call();

      maxSupply = await store
        .getState()
        .blockchain.smartContract.methods.maxSupply()
        .call();

      paused = await store
        .getState()
        .blockchain.smartContract.methods.paused()
        .call();

      maxMintAmount = await store
        .getState()
        .blockchain.smartContract.methods.maxMintAmount()
        .call();

      // console.log('fetchData::totalSupply', totalSupply);
      // console.log('fetchData::cost', cost);
      // console.log('fetchData::maxSupply', maxSupply);
      // console.log('fetchData::paused', paused);
      // console.log('fetchData::onlyWhitelisted', onlyWhitelisted);
      // console.log('fetchData::isWhitelisted', isWhitelisted);
      // console.log('fetchData::maxMintAmount', maxMintAmount);

      dispatch(
        fetchDataSuccess({
          totalSupply,
          cost,
          maxSupply,
          paused,
          onlyWhitelisted,
          isWhitelisted,
          maxMintAmount,
        }),
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed('Could not load data from contract.'));
    }
  };
};
