import * as s from '../styles/globalStyles';
import { connect } from '../redux/blockchain/blockchainActions';
import React from 'react';
import {
  StyledButton,
  StyledLink,
  StyledRectButton,
} from '../styles/containerStyles';

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

function MintingContainer(
  data,
  CONFIG,
  blockchain,
  dispatch,
  getData,
  feedback,
  claimingNft,
  decrementMintAmount,
  mintAmount,
  incrementMintAmount,
  claimNFTs,
) {
  return (
    <s.Container
      flex={2}
      jc={'center'}
      ai={'center'}
      style={{
        backgroundColor: 'var(--accent)',
        opacity: 0.9,
        padding: 24,
        borderRadius: 0,
        border: '1px solid #000',
        // boxShadow: '0px 5px 11px 2px rgba(0,0,0,0.7)',
      }}
    >
      <s.TextTitle
        style={{
          textAlign: 'center',
          fontSize: 50,
          fontWeight: 'bold',
          color: 'var(--accent-text)',
        }}
      >
        {data.totalSupply}/{data.maxSupply ? data.maxSupply : CONFIG.MAX_SUPPLY}
      </s.TextTitle>
      <s.TextDescription
        style={{
          textAlign: 'center',
          color: 'var(--secondary-text)',
        }}
      >
        <StyledLink target={'_blank'} href={CONFIG.SCAN_LINK}>
          {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
        </StyledLink>
      </s.TextDescription>
      <s.SpacerSmall />
      {Number(data.totalSupply) >=
      (Number(data.maxSupply) ? Number(data.maxSupply) : CONFIG.MAX_SUPPLY) ? (
        <>
          <s.TextTitle
            style={{ textAlign: 'center', color: 'var(--accent-text)' }}
          >
            The sale has ended.
          </s.TextTitle>
          <s.TextDescription
            style={{ textAlign: 'center', color: 'var(--accent-text)' }}
          >
            You can still find {CONFIG.NFT_NAME} on
          </s.TextDescription>
          <s.SpacerSmall />
          <StyledLink target={'_blank'} href={CONFIG.MARKETPLACE_LINK}>
            {CONFIG.MARKETPLACE}
          </StyledLink>
        </>
      ) : (
        <>
          <s.TextTitle
            style={{ textAlign: 'center', color: 'var(--accent-text)' }}
          >
            1 Dosdle costs{' '}
            {data.cost ? Number(data.cost / 1e18) : CONFIG.DISPLAY_COST}{' '}
            {CONFIG.NETWORK.SYMBOL}.
          </s.TextTitle>
          <s.SpacerXSmall />
          <s.TextDescription
            style={{ textAlign: 'center', color: 'var(--accent-text)' }}
          >
            Excluding gas fees.
          </s.TextDescription>

          <s.SpacerSmall />
          {blockchain.account === '' || blockchain.smartContract === null ? (
            <s.Container ai={'center'} jc={'center'}>
              <s.TextDescription
                style={{
                  textAlign: 'center',
                  color: 'var(--accent-text)',
                }}
              >
                Connect to the {CONFIG.NETWORK.NAME} network
              </s.TextDescription>
              <s.SpacerSmall />
              <StyledButton
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(connect());
                  getData();
                }}
              >
                CONNECT
              </StyledButton>
              {blockchain.errorMsg !== '' ? (
                <>
                  <s.SpacerSmall />
                  <s.TextDescription
                    style={{
                      textAlign: 'center',
                      color: 'var(--accent-text)',
                    }}
                  >
                    {blockchain.errorMsg}
                  </s.TextDescription>
                </>
              ) : null}
            </s.Container>
          ) : (
            <>
              <s.TextDescription
                style={{
                  textAlign: 'center',
                  color: 'var(--accent-text)',
                }}
              >
                {feedback}
              </s.TextDescription>
              <s.SpacerMedium />
              <s.Container ai={'center'} jc={'center'} fd={'row'}>
                <StyledRectButton
                  style={{ lineHeight: 0.4 }}
                  disabled={claimingNft ? 1 : 0}
                  onClick={(e) => {
                    e.preventDefault();
                    decrementMintAmount();
                  }}
                >
                  -
                </StyledRectButton>
                <s.SpacerMedium />
                <s.TextDescription
                  style={{
                    textAlign: 'center',
                    color: 'var(--accent-text)',
                  }}
                >
                  {mintAmount}
                </s.TextDescription>
                <s.SpacerMedium />
                <StyledRectButton
                  disabled={claimingNft ? 1 : 0}
                  onClick={(e) => {
                    e.preventDefault();
                    incrementMintAmount();
                  }}
                >
                  +
                </StyledRectButton>
              </s.Container>
              <s.SpacerSmall />
              <s.Container ai={'center'} jc={'center'} fd={'row'}>
                <StyledButton
                  disabled={claimingNft ? 1 : 0}
                  onClick={(e) => {
                    e.preventDefault();
                    claimNFTs();
                    getData();
                  }}
                >
                  {claimingNft ? 'BUSY' : 'BUY'}
                </StyledButton>
              </s.Container>
            </>
          )}
        </>
      )}
      <s.SpacerMedium />
    </s.Container>
  );
}
export default MintingContainer;
