import * as s from '../styles/globalStyles';
import React from 'react';
import { StyledLink } from '../styles/containerStyles';

function ContainerPaused(message) {
  return (
    <s.Container
      flex={2}
      jc={'center'}
      ai={'center'}
      style={{
        backgroundColor: 'var(--accent)',
        opacity: 0.9,
        padding: 24,
        borderRadius: 0,
        border: '1px solid #000',
        // border: "4px dashed var(--secondary)",
        // boxShadow: '0px 5px 11px 2px rgba(0,0,0,0.7)',
      }}
    >
      <s.TextTitle
        style={{
          textAlign: 'center',
          fontSize: 45,
          fontWeight: 'bold',
          color: 'var(--accent-text)',
        }}
      >
        {message}
      </s.TextTitle>
      <s.TextDescription
        style={{
          textAlign: 'center',
          color: 'var(--secondary-text)',
        }}
      >
        <StyledLink
          target={'_blank'}
          href="https://twitter.com/dostown_wedonft"
        >
          please write us on Twitter
        </StyledLink>
      </s.TextDescription>
    </s.Container>
  );
}

export default ContainerPaused;
