const initialState = {
  loading: false,
  totalSupply: 0,
  cost: 0,
  maxSupply: 0,
  paused: false,
  onlyWhitelisted: false,
  isWhitelisted: false,
  maxMintAmount: 0,
  error: false,
  errorMsg: '',
};

const dataReducer = (state = initialState, action) => {
  console.log('dataReducer', action.type);
  switch (action.type) {
    case 'CHECK_DATA_REQUEST':
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: '',
      };
    case 'CHECK_DATA_SUCCESS':
      return {
        ...state,
        loading: false,
        totalSupply: action.payload.totalSupply,
        cost: action.payload.cost,
        maxSupply: action.payload.maxSupply,
        paused: action.payload.paused,
        onlyWhitelisted: action.payload.onlyWhitelisted,
        isWhitelisted: action.payload.isWhitelisted,
        maxMintAmount: action.payload.maxMintAmount,
        error: false,
        errorMsg: '',
      };
    case 'CHECK_DATA_FAILED':
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
